<template>
  <v-card class="pa-4" flat>
    <div class="headline font-weight-medium">
      {{ title }}
    </div>
    <v-tabs v-model="tab" center-active @change="tabValue">
      <v-tab>
        {{ $t("set_time.tiers") }}
      </v-tab>
      <v-tab>
        {{ $t("set_time.class") }}
      </v-tab>
      <v-tab>
        {{ $t("set_time.all_grade") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-divider class="mt-3" />
      <v-tab-item>
        <GradeOrClass :init="initRoute" isGrade />
      </v-tab-item>
      <v-tab-item>
        <GradeOrClass :init="$route.query.child_tab_student == 1" />
      </v-tab-item>
      <v-tab-item>
        <StudentOrEmploye
          :init="$route.query.child_tab_student == 2"
          :isTeacher="false"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import i18n from "@/i18n";

export default {
  components: {
    GradeOrClass: () => import("./GradeOrClass"),
    StudentOrEmploye: () => import("./StudentOrEmploye")
  },
  props: {
    init: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    initRoute() {
      const route = this.$route;
      return (
        route.query.child_tab_student == 0 ||
        route.query.tab == 0 ||
        route.fullPath === "/master/set_time"
      );
    }
  },
  data() {
    return {
      tab: this.$route.query.child_tab_student | 0,
      title: i18n.t("set_time.enter_school_schedule")
    };
  },
  methods: {
    tabValue(tab) {
      switch (tab) {
        case 0:
          this.title = i18n.t("set_time.enter_school_schedule");
          break;
        case 1:
          this.title = i18n.t("set_time.schedule_entry_per_level");
          break;
        case 2:
          this.title = i18n.t("set_time.entry_per_class_schedule");
          break;
        default:
          this.title = i18n.t("set_time.enter_school_schedule");
          break;
      }
      this.$router.push({ name: "SetTime", query: { child_tab_student: tab } });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-select__slot {
  .v-label {
    font-size: 14px;
  }
}
</style>
